<template>
  <div>
    <div class="uk-container uk-container-expand card-scrollable">
      <div
        v-if="pathName.includes('mitra-potensial')"
        class="uk-margin-top uk-margin-bottom"
        uk-grid
      >
        <div class="uk-width-2-3@s font-header">
          {{ dataHeader.title }}
        </div>
        <div
          v-if="dataHeader.isBreadcrumb"
          class="uk-width-1-3@s"
        >
          <bread-crumb :bread-crumb="dataHeader.breadcrumb" />
        </div>
      </div>
      <div class="uk-margin-bottom">
        <!-- Section INFORMASI PEMOHON -->
        <div class="uk-card uk-card-default uk-padding-small kandang-card uk-margin-bottom">
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1">
              <strong>
                Informasi Pemohon
              </strong>
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l uk-width-1-2@xl">
              <div
                class="uk-child-width-expand@s uk-grid-small"
                uk-grid
              >
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                  Nama Pemohon
                </div>
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                  <input
                    v-model="formData.applicant_name"
                    v-validate="{ required: true, regex: '^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$' }"
                    class="uk-input"
                    type="text"
                    name="applicant_name"
                    placeholder="Masukkan Nama"
                    :class="{'uk-form-danger': errors.has('applicant_name')}"
                    disabled
                  >
                  <div
                    v-show="errors.has('applicant_name')"
                    class="uk-text-small uk-text-danger"
                  >
                    {{ errors.first('applicant_name') }}
                  </div>
                </div>
              </div>
              <div
                class="uk-child-width-expand@s uk-grid-small"
                uk-grid
              >
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                  Nomor Handphone
                </div>
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                  <input
                    v-model="formData.phone_number"
                    v-validate="{ required: true, regex: '^([0-9]+)$', max: '13', min: '10' }"
                    class="uk-input"
                    type="text"
                    name="phone_number"
                    placeholder="Masukkan Nomor Handphone"
                    :class="{'uk-form-danger': errors.has('phone_number')}"
                    disabled
                  >
                  <div
                    v-show="errors.has('phone_number')"
                    class="uk-text-small uk-text-danger"
                  >
                    {{ errors.first('phone_number') }}
                  </div>
                </div>
              </div>
              <!-- <div
                v-if="formData.document_number" 
                class="uk-child-width-expand@s uk-grid-small"
                uk-grid
              >
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                  Nomor Dokumen
                </div>
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                  <input
                    v-model="formData.document_number"
                    v-validate="{ required: true, regex: '^([0-9]+)$' }"
                    class="uk-input"
                    type="text"
                    name="document_number"
                    placeholder="Masukkan Nomor"
                    :class="{'uk-form-danger': errors.has('document_number')}"
                    :disabled="isEdit"
                  >
                  <div
                    v-show="errors.has('document_number')"
                    class="uk-text-small uk-text-danger"
                  >
                    {{ errors.first('document_number') }}
                  </div>
                </div>
              </div> -->
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l uk-width-1-2@xl">
              <div
                class="uk-child-width-expand@s uk-grid-small"
                uk-grid
              >
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-small-top">
                  Alamat Pemohon
                </div>
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                  <textarea
                    v-model="formData.applicant_address"
                    v-validate="{ required: true }"
                    name="applicant_address"
                    placeholder="Masukkan Alamat Pemohon"
                    rows="5"
                    :class="{'uk-form-danger': errors.has('applicant_address'), 'uk-textarea': true}"
                    disabled
                  />
                  <div
                    v-show="errors.has('applicant_address')"
                    class="uk-text-small uk-text-danger"
                  >
                    {{ errors.first('applicant_address') }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Section INFORMASI KANDANG SATU -->
        <div class="uk-card uk-card-default uk-padding-small kandang-card uk-margin-bottom">
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1">
              <strong>
                Informasi Kandang 1/2
              </strong>
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l uk-width-1-2@xl">
              <div
                class="uk-child-width-expand@s uk-grid-small"
                uk-grid
              >
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                  Nama Kandang<span
                    v-if="isSav"
                    class="uk-text-danger"
                  >*</span>
                </div>
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                  <input
                    v-model="formData.cage_name"
                    v-validate="{ required: true, regex: '^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$' }"
                    class="uk-input"
                    type="text"
                    name="cage_name"
                    placeholder="Masukkan Nama"
                    :class="{'uk-form-danger': errors.has('cage_name')}"
                    :disabled="isEdit"
                  >
                  <div
                    v-show="errors.has('cage_name')"
                    class="uk-text-small uk-text-danger"
                  >
                    {{ errors.first('cage_name') }}
                  </div>
                </div>
              </div>
              <div
                class="uk-child-width-expand@s uk-grid-small"
                uk-grid
              >
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-small-top">
                  Alamat Kandang<span
                    v-if="isSav"
                    class="uk-text-danger"
                  >*</span>
                </div>
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                  <textarea
                    v-model="formData.cage_address"
                    name="cage_address"
                    placeholder="Masukkan Alamat Kandang"
                    rows="5"
                    :class="{'uk-form-danger': errors.has('cage_address'), 'uk-textarea': true}"
                    disabled
                  />
                  <div
                    v-show="errors.has('cage_address')"
                    class="uk-text-small uk-text-danger"
                  >
                    {{ errors.first('cage_address') }}
                  </div>
                </div>
              </div>
              <div
                class="uk-child-width-expand@s uk-grid-small"
                uk-grid
              >
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                  Koordinat<span
                    v-if="isSav"
                    class="uk-text-danger"
                  >*</span>
                </div>
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                  <div
                    class="uk-inline"
                  >
                    <input
                      v-model="formData.coordinate_name"
                      v-validate="{required: true}"
                      class="uk-input"
                      type="text"
                      name="coordinate_name"
                      placeholder="Masukkan Koordinat"
                      :class="{'uk-form-danger': errors.has('coordinate_name')}"
                      :disabled="isEdit"
                    >
                    <div
                      v-show="errors.has('coordinate_name')||errorGeotagMessage"
                      class="uk-text-small uk-text-danger"
                    >
                      {{ errors.first('coordinate_name') || errorGeotagMessage }}
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="uk-child-width-expand@s uk-grid-small"
                uk-grid
              >
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                  Status Lahan<span
                    v-if="isSav"
                    class="uk-text-danger"
                  >*</span>
                </div>
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                  <multiselect
                    v-show="!isEdit || isSav" 
                    id="land_status"
                    v-model="formData.land_status"
                    v-validate="'required'"
                    label="name" 
                    name="land_status" 
                    track-by="name" 
                    placeholder="Pilih Status" 
                    open-direction="bottom"
                    :options="listLandStatus"
                    :searchable="true"
                    :close-on-select="true" 
                    :max-height="500"
                    :show-no-results="true"
                    :disabled="isEdit"
                  >
                    <span slot="noResult">Oops! Data tidak ditemukan.</span>
                  </multiselect>
                  <input
                    v-if="isEdit && !isSav"
                    v-model="formData.land_status.name"
                    class="uk-input"
                    disabled
                  >
                  <div
                    v-show="errors.has('land_status')"
                    class="uk-text-small uk-text-danger"
                  >
                    {{ errors.first('land_status') }}
                  </div>
                </div>
              </div>
              <!-- <div
                class="uk-child-width-expand@s uk-grid-small"
                uk-grid
              >
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                  <span>Kondisi Lahan<span
                    v-if="isSav"
                    class="uk-text-danger"
                  >*</span></span>
                  <img
                    v-lazy="`${images}/icon/tooltip.svg`"
                    alt=""
                    class="uk-margin-small-left"
                    uk-tooltip="Cukup rata kondisi lahan dan mempunyai jalur drainase"
                  >
                </div>
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                  <div class="uk-margin uk-grid-small uk-child-width-auto uk-grid">
                    <label>
                      <input
                        v-model="formData.land_condition"
                        class="uk-radio"
                        type="radio"
                        name="land_condition"
                        value="1"
                        :disabled="isEdit"
                      > Sesuai
                    </label>
                    <label>
                      <input
                        v-model="formData.land_condition"
                        class="uk-radio"
                        type="radio"
                        name="land_condition"
                        value="0"
                        :disabled="isEdit"
                      > Tidak Sesuai
                    </label>
                    <div
                      v-show="errorLandCondition"
                      class="uk-text-small uk-text-danger"
                    >
                      {{ errorLandCondition }}
                    </div>
                  </div>
                </div>
              </div> -->
              <div
                class="uk-child-width-expand@s uk-grid-small uk-margin-top"
                uk-grid
              >
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                  <span>Ukuran Lahan<span
                    v-if="isSav"
                    class="uk-text-danger"
                  >*</span></span>
                  <img
                    v-lazy="`${images}/icon/tooltip.svg`"
                    alt=""
                    class="uk-margin-small-left"
                    :uk-tooltip="ukuranLahanTooltip"
                  >
                </div>
                <div
                  class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl uk-child-width-expand@s uk-grid-small"
                  uk-grid
                >
                  <div class="uk-width-1-2">
                    <div class="uk-inline">
                      <input
                        v-model="formData.long_land_size"
                        v-validate="{ required: true, regex: '^([0-9]+)$' }"
                        class="uk-input"
                        type="number"
                        name="long_land_size"
                        placeholder="Panjang"
                        :class="{'uk-form-danger': errors.has('long_land_size')}"
                        :disabled="isEdit"
                      >
                      <span
                        class="uk-form-icon uk-form-icon-flip"
                        uk-icon
                      >
                        M
                      </span>
                    </div>
                    <div
                      v-show="errors.has('long_land_size')"
                      class="uk-text-small uk-text-danger"
                    >
                      {{ errors.first('long_land_size') }}
                    </div>
                  </div>
                  <div class="uk-width-1-2">
                    <div class="uk-inline">
                      <span
                        class="uk-form-icon uk-form-icon-flip"
                        uk-icon
                      >
                        M
                      </span>
                      <input
                        v-model="formData.wide_land_size"
                        v-validate="{ required: true, regex: '^([0-9]+)$' }"
                        class="uk-input"
                        type="number"
                        name="wide_land_size"
                        placeholder="Lebar"
                        :class="{'uk-form-danger': errors.has('wide_land_size')}"
                        :disabled="isEdit"
                      >
                    </div>
                    <div
                      v-show="errors.has('wide_land_size')"
                      class="uk-text-small uk-text-danger"
                    >
                      {{ errors.first('wide_land_size') }}
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div
                class="uk-child-width-expand@s uk-grid-small"
                uk-grid
              >
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                  Ukuran Diatas Sesuai?<span
                    v-if="isSav"
                    class="uk-text-danger"
                  >*</span>
                </div>
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                  <div class="uk-margin uk-grid-small uk-child-width-auto uk-grid">
                    <label>
                      <input
                        v-model="formData.right_size"
                        class="uk-radio"
                        type="radio"
                        name="right_size"
                        value="1"
                        :disabled="isEdit"
                      > Sesuai
                    </label>
                    <label>
                      <input
                        v-model="formData.right_size"
                        class="uk-radio"
                        type="radio"
                        name="right_size"
                        value="0"
                        :disabled="isEdit"
                      > Tidak Sesuai
                    </label>
                    <div
                      v-show="errorRightSize"
                      class="uk-text-small uk-text-danger"
                    >
                      {{ errorRightSize }}
                    </div>
                  </div>
                </div>
              </div> -->
              <div
                class="uk-child-width-expand@s uk-grid-small"
                uk-grid
              >
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                  Fasilitas Pendukung<span
                    v-if="isSav"
                    class="uk-text-danger"
                  >*</span>
                </div>
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                  <multiselect 
                    v-show="!isEdit || isSav"
                    id="supporting_facilities" 
                    v-model="formData.supporting_facilities"
                    v-validate="'required'"
                    label="name" 
                    name="supporting_facilities" 
                    track-by="name" 
                    placeholder="Pilih Fasilitas" 
                    open-direction="bottom"
                    :options="listSupportingFacilities"
                    :searchable="true"
                    :close-on-select="true" 
                    :max-height="500"
                    :show-no-results="true"
                    :disabled="isEdit"
                  >
                    <span slot="noResult">Oops! Data tidak ditemukan.</span>
                  </multiselect>
                  <input
                    v-if="isEdit && !isSav"
                    v-model="formData.supporting_facilities.name"
                    class="uk-input"
                    disabled
                  >
                  <div
                    v-show="errors.has('supporting_facilities')"
                    class="uk-text-small uk-text-danger"
                  >
                    {{ errors.first('supporting_facilities') }}
                  </div>
                  <template v-if="formData.supporting_facilities && formData.supporting_facilities.name == 'Lainnya'">
                    <input
                      v-model="formData.other_supporting_facilities"
                      v-validate="{ required: true, regex: '^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$' }"
                      class="uk-input"
                      type="text"
                      name="other_supporting_facilities"
                      placeholder="Masukkan Fasilitas Lainnya"
                      :class="{'uk-form-danger': errors.has('other_supporting_facilities'), 'uk-margin-top': true }"
                      :disabled="isEdit"
                    >
                    <div
                      v-show="errors.has('other_supporting_facilities')"
                      class="uk-text-small uk-text-danger"
                    >
                      {{ errors.first('other_supporting_facilities') }}
                    </div>
                  </template>
                </div>
              </div>
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l uk-width-1-2@xl">
              <div
                class="uk-child-width-expand@s uk-grid-small"
                uk-grid
              >
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                  Arah Kandang<span
                    v-if="isSav"
                    class="uk-text-danger"
                  >*</span>
                </div>
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                  <multiselect 
                    v-show="!isEdit || isSav"
                    id="cage_direction"
                    v-model="formData.cage_direction"
                    v-validate="'required'"
                    label="name" 
                    name="cage_direction" 
                    track-by="name" 
                    placeholder="Pilih Arah" 
                    open-direction="bottom"
                    :options="listCageDirection"
                    :searchable="true"
                    :close-on-select="true" 
                    :max-height="500"
                    :show-no-results="true"
                    :disabled="isEdit"
                  >
                    <span slot="noResult">Oops! Data tidak ditemukan.</span>
                  </multiselect>
                  <input
                    v-if="isEdit && !isSav"
                    v-model="formData.cage_direction.name"
                    class="uk-input"
                    disabled
                  >
                  <div
                    v-show="errors.has('cage_direction')"
                    class="uk-text-small uk-text-danger"
                  >
                    {{ errors.first('cage_direction') }}
                  </div>
                </div>
              </div>
              <div
                class="uk-child-width-expand@s uk-grid-small"
                uk-grid
              >
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                  <span>&#8226; Kondisi Sebelah Utara<span
                    v-if="isSav"
                    class="uk-text-danger"
                  >*</span></span>
                </div>
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                  <input
                    v-model="formData.north_side"
                    v-validate="{ required: true }"
                    class="uk-input"
                    type="text"
                    name="north_side"
                    maxlength="50"
                    placeholder="Masukkan Kondisi"
                    :class="{'uk-form-danger': errors.has('north_side')}"
                    :disabled="isEdit"
                  >
                  <div
                    v-show="errors.has('north_side')"
                    class="uk-text-small uk-text-danger"
                  >
                    {{ errors.first('north_side') }}
                  </div>
                </div>
              </div>
              <div
                class="uk-child-width-expand@s uk-grid-small"
                uk-grid
              >
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                  <span>&#8226; Kondisi Sebelah Timur<span
                    v-if="isSav"
                    class="uk-text-danger"
                  >*</span></span>
                </div>
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                  <input
                    v-model="formData.east_side"
                    v-validate="{ required: true }"
                    class="uk-input"
                    type="text"
                    name="east_side"
                    maxlength="50"
                    placeholder="Masukkan Kondisi"
                    :class="{'uk-form-danger': errors.has('east_side')}"
                    :disabled="isEdit"
                  >
                  <div
                    v-show="errors.has('east_side')"
                    class="uk-text-small uk-text-danger"
                  >
                    {{ errors.first('east_side') }}
                  </div>
                </div>
              </div>
              <div
                class="uk-child-width-expand@s uk-grid-small"
                uk-grid
              >
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                  <span>&#8226; Kondisi Sebelah Barat<span
                    v-if="isSav"
                    class="uk-text-danger"
                  >*</span></span>
                </div>
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                  <input
                    v-model="formData.west_side"
                    v-validate="{ required: true }"
                    class="uk-input"
                    type="text"
                    name="west_side"
                    maxlength="50"
                    placeholder="Masukkan Kondisi"
                    :class="{'uk-form-danger': errors.has('west_side')}"
                    :disabled="isEdit"
                  >
                  <div
                    v-show="errors.has('west_side')"
                    class="uk-text-small uk-text-danger"
                  >
                    {{ errors.first('west_side') }}
                  </div>
                </div>
              </div>
              <div
                class="uk-child-width-expand@s uk-grid-small"
                uk-grid
              >
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                  <span>&#8226; Kondisi Sebelah Selatan<span
                    v-if="isSav"
                    class="uk-text-danger"
                  >*</span></span>
                </div>
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                  <input
                    v-model="formData.south_side"
                    v-validate="{ required: true }"
                    class="uk-input"
                    type="text"
                    name="south_side"
                    maxlength="50"
                    placeholder="Masukkan Kondisi"
                    :class="{'uk-form-danger': errors.has('south_side')}"
                    :disabled="isEdit"
                  >
                  <div
                    v-show="errors.has('south_side')"
                    class="uk-text-small uk-text-danger"
                  >
                    {{ errors.first('south_side') }}
                  </div>
                </div>
              </div>
              <div
                class="uk-child-width-expand@s uk-grid-small"
                uk-grid
              >
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                  Jalan Ke Kandang<span
                    v-if="isSav"
                    class="uk-text-danger"
                  >*</span>
                </div>
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                  <div class="uk-inline">
                    <span
                      class="uk-form-icon uk-form-icon-flip"
                      uk-icon
                    >
                      M
                    </span>
                    <input
                      v-model="formData.walk_to_cage"
                      v-validate="{ required: true }"
                      class="uk-input"
                      type="number"
                      name="walk_to_cage"
                      placeholder="Masukkan Jarak"
                      :disabled="isEdit"
                    >
                  </div>
                  <div
                    v-show="errors.has('walk_to_cage')"
                    class="uk-text-small uk-text-danger"
                  >
                    {{ errors.first('walk_to_cage') }}
                  </div>
                </div>
              </div>
              <div
                class="uk-child-width-expand@s uk-grid-small"
                uk-grid
              >
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                  Kantor Ke Kandang
                </div>
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                  <div class="uk-inline">
                    <span
                      class="uk-form-icon uk-form-icon-flip"
                      uk-icon
                    >
                      KM
                    </span>
                    <input
                      v-model="formData.office_to_cage"
                      v-validate="{ regex: '^([0-9]+)$' }"
                      class="uk-input"
                      type="numeric"
                      name="office_to_cage"
                      placeholder="Masukkan Jarak"
                      :class="{'uk-form-danger': errors.has('office_to_cage')}"
                      :disabled="isEdit"
                    >
                    <div
                      v-show="errors.has('office_to_cage')"
                      class="uk-text-small uk-text-danger"
                    >
                      {{ errors.first('office_to_cage') }}
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="uk-child-width-expand@s uk-grid-small"
                uk-grid
              >
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                  Kandang Ke Pemukiman<span
                    v-if="isSav"
                    class="uk-text-danger"
                  >*</span>
                </div>
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                  <div class="uk-inline">
                    <span
                      class="uk-form-icon uk-form-icon-flip"
                      uk-icon
                    >
                      M
                    </span>
                    <input
                      v-model="formData.cage_to_settlement"
                      v-validate="{ required: true, regex: '^([0-9]+)$' }"
                      class="uk-input"
                      type="numeric"
                      name="cage_to_settlement"
                      placeholder="Masukkan Jarak"
                      :class="{'uk-form-danger': errors.has('cage_to_settlement')}"
                      :disabled="isEdit"
                    >
                  </div>
                  <div
                    v-show="errors.has('cage_to_settlement')"
                    class="uk-text-small uk-text-danger"
                  >
                    {{ errors.first('cage_to_settlement') }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Section INFORMASI KANDANG DUA -->
        <div
          class="uk-card uk-card-default uk-padding-small kandang-card uk-margin-bottom"
        >
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1">
              <strong>
                Informasi Kandang 2/2
              </strong>
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l uk-width-1-2@xl">
              <div
                class="uk-child-width-expand@s uk-grid-small"
                uk-grid
              >
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                  Akses Ke Lokasi Kandang<span
                    v-if="isSav"
                    class="uk-text-danger"
                  >*</span>
                </div>
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                  <multiselect 
                    v-show="!isEdit || isSav"
                    id="access_to_location_cage"
                    v-model="formData.access_to_location_cage"
                    v-validate="'required'"
                    name="access_to_location_cage" 
                    placeholder="Pilih" 
                    open-direction="bottom"
                    :options="listAccessCage"
                    :searchable="true"
                    :close-on-select="true" 
                    :max-height="500"
                    :show-no-results="true"
                    :disabled="isEdit"
                  >
                    <span slot="noResult">Oops! Data tidak ditemukan.</span>
                  </multiselect>
                  <input
                    v-if="isEdit && !isSav"
                    v-model="formData.access_to_location_cage"
                    class="uk-input"
                    disabled
                  >
                  <div
                    v-show="errors.has('access_to_location_cage')"
                    class="uk-text-small uk-text-danger"
                  >
                    {{ errors.first('access_to_location_cage') }}
                  </div>
                </div>
              </div>
              <div
                class="uk-child-width-expand@s uk-grid-small"
                uk-grid
              >
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                  Apakah Listrik Tersedia?<span
                    v-if="isSav"
                    class="uk-text-danger"
                  >*</span>
                </div>
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                  <div class="uk-margin uk-grid-small uk-child-width-auto uk-grid">
                    <label>
                      <input
                        v-model="formData.electricity_available"
                        v-validate="'required'"
                        class="uk-radio"
                        type="radio"
                        name="electricity_available"
                        value="1"
                        :disabled="isEdit"
                      >
                      Tersedia
                    </label>
                    <label>
                      <input
                        v-model="formData.electricity_available"
                        v-validate="'required'"
                        class="uk-radio"
                        type="radio"
                        name="electricity_available"
                        value="2"
                        :disabled="isEdit"
                      >
                      Tidak Tersedia
                    </label>
                    <div
                      v-show="errorElectricityAvailable"
                      class="uk-text-danger"
                    >
                      {{ errorElectricityAvailable }}
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="uk-child-width-expand@s uk-grid-small uk-margin-top"
                uk-grid
              >
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                  Daya Listrik<span
                    v-if="isSav"
                    class="uk-text-danger"
                  >*</span>
                </div>
                <div
                  class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl uk-child-width-expand@s uk-grid-small"
                  uk-grid
                >
                  <div class="uk-inline uk-width-1-1">
                    <span
                      class="uk-form-icon uk-form-icon-flip"
                      uk-icon
                    >Watt</span>
                    <input
                      v-model="formData.electrical_power"
                      v-validate="{ required: true, regex: '^([1-9][0-9]*)$' }"
                      class="uk-input"
                      type="number"
                      name="electrical_power"
                      placeholder="Masukan Daya"
                      :class="{'uk-form-danger': errors.has('electrical_power')}"
                      :disabled="hasElectrical || isEdit"
                    >
                  </div>
                  <div
                    v-show="errors.has('electrical_power') && !hasElectrical"
                    class="uk-text-small uk-text-danger"
                  >
                    {{ errors.first('electrical_power') }}
                  </div>
                </div>
              </div>
              <div
                class="uk-child-width-expand@s uk-grid-small uk-margin-top"
                uk-grid
              >
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                  Stabilitas Listrik Di Lokasi
                </div>
                <div
                  class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl uk-child-width-expand@s uk-grid-small"
                  uk-grid
                >
                  <div class="uk-width-1-2">
                    <div class="uk-inline">
                      <span
                        class="uk-form-icon uk-form-icon-flip"
                        uk-icon
                      >Watt</span>
                      <input
                        v-model="formData.location_power_stability_start"
                        v-validate="{ required: false, regex: '^\\d+(\\.\\d{1,2})?$' }"
                        class="uk-input"
                        type="numeric"
                        name="location_power_stability_start"
                        :class="{'uk-form-danger': errors.has('location_power_stability_start')}"
                        :disabled="hasElectrical || isEdit"
                      >
                      <div
                        v-show="errors.has('location_power_stability_start') && !hasElectrical"
                        class="uk-text-small uk-text-danger uk-position-absolute"
                      >
                        {{ 'invalid format!' }}
                      </div>
                    </div>
                  </div>
                  <div class="uk-width-1-2">
                    <div class="uk-inline">
                      <span
                        class="uk-form-icon uk-form-icon-flip"
                        uk-icon
                      >Watt</span>
                      <input
                        v-model="formData.location_power_stability_end"
                        v-validate="{ required: false, regex: '^\\d+(\\.\\d{1,2})?$' }"
                        class="uk-input"
                        type="numeric"
                        name="location_power_stability_end"
                        :class="{'uk-form-danger': errors.has('location_power_stability_end')}"
                        :disabled="hasElectrical || isEdit"
                      >
                      <div
                        v-show="errors.has('location_power_stability_end') && !hasElectrical"
                        class="uk-text-small uk-text-danger uk-position-absolute"
                      >
                        {{ 'invalid format!' }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="uk-child-width-expand@s uk-grid-small uk-margin-top"
                uk-grid
              >
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                  Listrik Sering Padam?<span
                    v-if="isSav"
                    class="uk-text-danger"
                  >*</span>
                </div>
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                  <div class="uk-margin uk-grid-small uk-child-width-auto uk-grid">
                    <label>
                      <input
                        v-model="formData.frequent_power_outages"
                        class="uk-radio"
                        type="radio"
                        name="frequent_power_outages"
                        value="1"
                        :disabled="hasElectrical || isEdit"
                      > Sering Padam
                    </label>
                    <label>
                      <input
                        v-model="formData.frequent_power_outages"
                        class="uk-radio"
                        type="radio"
                        name="frequent_power_outages"
                        value="2"
                        :disabled="hasElectrical || isEdit"
                      > Tidak Sering
                    </label>
                    <div
                      v-show="errorFrequentPowerOutages"
                      class="uk-text-small uk-text-danger"
                    >
                      {{ hasElectrical ? '' : errorFrequentPowerOutages }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l uk-width-1-2@xl">
              <div
                class="uk-child-width-expand@s uk-grid-small"
                uk-grid
              >
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                  Sumber Air<span
                    v-if="isSav"
                    class="uk-text-danger"
                  >*</span>
                </div>
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                  <multiselect 
                    v-show="!isEdit || isSav"
                    id="water_sources" 
                    v-model="formData.water_sources"
                    v-validate="'required'"
                    name="water_sources" 
                    placeholder="Pilih Sumber" 
                    open-direction="bottom"
                    :options="listWaterResources"
                    :searchable="true"
                    :close-on-select="true" 
                    :max-height="500"
                    :show-no-results="true"
                    :disabled="isEdit"
                  >
                    <span slot="noResult">Oops! Data tidak ditemukan.</span>
                  </multiselect>
                  <input
                    v-if="isEdit && !isSav && formData.water_sources"
                    v-model="formData.water_sources"
                    class="uk-input"
                    disabled
                  >
                  <div
                    v-show="errors.has('water_sources')"
                    class="uk-text-small uk-text-danger"
                  >
                    {{ errors.first('water_sources') }}
                  </div>
                </div>
              </div>
              <div
                class="uk-child-width-expand@s uk-grid-small"
                uk-grid
              >
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                  RUTR/RDTR Kandang<span
                    v-if="isSav"
                    class="uk-text-danger"
                  >*</span>
                </div>
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                  <div class="uk-margin uk-grid-small uk-child-width-auto uk-grid">
                    <label>
                      <input
                        v-model="formData.rutr_rdtr_cage"
                        class="uk-radio"
                        type="radio"
                        name="rutr_rdtr_cage"
                        value="1"
                        :disabled="isEdit"
                      > Sesuai
                    </label>
                    <label>
                      <input
                        v-model="formData.rutr_rdtr_cage"
                        class="uk-radio"
                        type="radio"
                        name="rutr_rdtr_cage"
                        value="2"
                        :disabled="isEdit"
                      > Tidak Sesuai
                    </label>
                    <div
                      v-show="errorRutr"
                      class="uk-text-small uk-text-danger"
                    >
                      {{ errorRutr }}
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="uk-child-width-expand@s uk-grid-small uk-margin-top"
                uk-grid
              >
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                  Ketinggian Wilayah<span
                    v-if="isSav"
                    class="uk-text-danger"
                  >*</span>
                </div>
                <div
                  class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl uk-child-width-expand@s uk-grid-small"
                  uk-grid
                >
                  <div class="uk-inline uk-width-1-1">
                    <input
                      v-model="formData.altitude_area"
                      v-validate="{ required: true, regex: '^\\d+(\\.\\d{1,2})?$' }"
                      class="uk-input"
                      type="number"
                      name="altitude_area"
                      placeholder="Masukan Ketinggian"
                      :class="{'uk-form-danger': errors.has('altitude_area')}"
                      :disabled="isEdit"
                    >
                    <span
                      class="uk-form-icon uk-form-icon-flip"
                      uk-icon
                    >Mdpl</span>
                  </div>
                  <div
                    v-show="errors.has('altitude_area')"
                    class="uk-text-small uk-text-danger"
                  >
                    {{ errors.first('altitude_area') }}
                  </div>
                </div>
              </div>
              <div
                class="uk-child-width-expand@s uk-grid-small uk-margin-top"
                uk-grid
              >
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                  Suhu Dilokasi<span
                    v-if="isSav"
                    class="uk-text-danger"
                  >*</span>
                </div>
                <div
                  class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl uk-child-width-expand@s uk-grid-small"
                  uk-grid
                >
                  <div class="uk-width-expand">
                    <div class="uk-inline">
                      <span
                        class="uk-form-icon uk-form-icon-flip"
                        uk-icon
                      >C</span>
                      <input
                        v-model="formData.location_temperature_start"
                        v-validate="{ 'required': true, regex: '^-?\\d+(\\.\\d{1,2})?$' }"
                        class="uk-input"
                        type="number"
                        name="location_temperature_start"
                        :class="{'uk-form-danger': errors.has('location_temperature_start')}"
                        :disabled="isEdit"
                      >
                    </div>
                    <div
                      v-show="(!standBy || isSav) && errors.has('location_temperature_start')"
                      class="uk-text-small uk-text-danger"
                    >
                      {{ !formData.location_temperature_start ? 'This field required' : 'Invalid_format!' }}
                    </div>
                  </div>
                  <div class="uk-width-auto uk-flex uk-flex-middle">
                    <span> - </span>
                  </div>
                  <div class="uk-width-expand">
                    <div class="uk-inline">
                      <span
                        class="uk-form-icon uk-form-icon-flip"
                        uk-icon
                      >C</span>
                      <input
                        v-model="formData.location_temperature_end"
                        v-validate="{ 'required': true, regex: '^-?\\d+(\\.\\d{1,2})?$' }"
                        class="uk-input"
                        type="number"
                        name="location_temperature_end"
                        :class="{'uk-form-danger': errors.has('location_temperature_end')}"
                        :disabled="isEdit"
                      >
                    </div>
                    <div
                      v-show="(!standBy || isSav) && errors.has('location_temperature_end')"
                      class="uk-text-small uk-text-danger"
                    >
                      {{ !formData.location_temperature_end ? 'This field required' : 'Invalid_format!' }}
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="uk-child-width-expand@s uk-grid-small uk-margin-top"
                uk-grid
              >
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                  Kelembapan Dilokasi
                </div>
                <div
                  class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl uk-child-width-expand@s uk-grid-small"
                  uk-grid
                >
                  <div class="uk-width-expand">
                    <div class="uk-inline">
                      <span
                        class="uk-form-icon uk-form-icon-flip"
                        uk-icon
                      >%</span>
                      <input
                        v-model="formData.location_humidity_start"
                        v-validate="{ required: false, regex: '^-?\\d+(\\.\\d{1,2})?$' }"
                        class="uk-input"
                        type="number"
                        name="location_humidity_start"
                        :class="{'uk-form-danger': errors.has('location_humidity_start')}"
                        :disabled="isEdit"
                      >
                    </div>
                    <div
                      v-show="errors.has('location_humidity_start')"
                      class="uk-text-small uk-text-danger uk-position-absolute"
                    >
                      {{ 'Invalid format!' }}
                    </div>
                  </div>
                  <div class="uk-width-auto uk-flex uk-flex-middle">
                    <span> - </span>
                  </div>
                  <div class="uk-width-expand">
                    <div class="uk-inline">
                      <span
                        class="uk-form-icon uk-form-icon-flip"
                        uk-icon
                      >%</span>
                      <input
                        v-model="formData.location_humidity_end"
                        v-validate="{ required: false, regex: '^-?\\d+(\\.\\d{1,2})?$' }"
                        class="uk-input"
                        type="number"
                        name="location_humidity_end"
                        :class="{'uk-form-danger': errors.has('location_humidity_end')}"
                        :disabled="isEdit"
                      >
                    </div>
                    <div
                      v-show="errors.has('location_humidity_end')"
                      class="uk-text-small uk-text-danger uk-position-absolute"
                    >
                      {{ 'Invalid format!' }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            v-show="isSav || !isEdit"
            class="uk-card uk-card-default uk-padding-small card-failed uk-margin-top"
          >
            <div
              class="uk-child-width-expand@s uk-grid-small"
              uk-grid
            >
              <div class="uk-width-1-1 attention-failed">
                <p>
                  Catatan Penting!
                </p>
                <span class="uk-display-block">Peternak harus mempersiapkan instalasi listrik, termasuk Genset dan air hingga menjangkau kandang.</span>
                <span class="uk-display-block">Kelengkapan: Toren air, pipa (termasuk siku dan konektor), instalasi bak minum (bak plastik dan klep pelampung), kabel listrik.</span>
                <span class="uk-display-block">Peternak mempersiapkan lahan agar dapat di bangun kandang diatasnya</span>
              </div>
            </div>
          </div>
        </div>

        <!-- Section FOTO LOKASI LAHAN -->
        <div class="uk-card uk-card-default uk-padding-small kandang-card uk-margin-bottom">
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1">
              <strong>
                Foto Lokasi Lahan
              </strong>
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l uk-width-1-2@xl">
              <div
                class="uk-child-width-expand@s uk-grid-small"
                uk-grid
              >
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                  Lahan Menghadap Barat
                </div>
                <div
                  v-if="!formData.west_land_photo"
                  class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl js-upload"
                  uk-form-custom
                >
                  <input
                    v-validate="{ 'required': formData.west_land_photo ? false : true }"
                    name="west_land_photo"
                    :class="{'uk-form-danger': errors.has('west_land_photo')}"
                    accept="image/png,image/jpg,image/jpeg"
                    type="file"
                    multiple
                    :disabled="isEdit"
                    @change="(event) => handleUploadFile(event, 'west_land_photo')"
                  >
                  <div
                    class="uk-inline"
                    tabindex="-1"
                  >
                    <img
                      v-lazy="`${images}/icon/logout.svg`"
                      alt="icon arrow"
                      class="uk-form-icon uk-form-icon-flip icon-upload"
                    >
                    <input
                      class="uk-input"
                      placeholder="Unggah Dokumen"
                    >
                  </div>
                  <div
                    v-show="errors.has('west_land_photo')"
                    class="uk-text-small uk-text-danger"
                  >
                    {{ errors.first('west_land_photo') }}
                  </div>
                </div>
                <div v-else>
                  <div 
                    :class="{'uk-input-disabled' : isEdit}"
                    class="uk-width-1-1 uk-input uk-flex uk-flex-between"
                  >
                    <span class="truncate">
                      {{ formData.west_land_photo.name }}
                    </span>
                    <div class="uk-flex">
                      <img
                        v-lazy="isEdit ? `${images}/icon/eye-line.svg` : `${images}/icon/close-green.svg`"
                        alt="icon action"
                        class="icon-upload img-click"
                        @click="handlePreviewImage('west_land_photo')"
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="uk-child-width-expand@s uk-grid-small"
                uk-grid
              >
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                  Lahan Menghadap Timur
                </div>
                <div
                  v-if="!formData.east_land_photo"
                  class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl js-upload"
                  uk-form-custom
                >
                  <input
                    v-validate="{ 'required': formData.east_land_photo ? false : true }"
                    name="east_land_photo"
                    :class="{'uk-form-danger': errors.has('east_land_photo')}"
                    accept="image/png,image/jpg,image/jpeg"
                    type="file"
                    multiple
                    :disabled="isEdit"
                    @change="(event) => handleUploadFile(event, 'east_land_photo')"
                  >
                  <div
                    class="uk-inline"
                    tabindex="-1"
                  >
                    <img
                      v-lazy="`${images}/icon/logout.svg`"
                      alt="icon arrow"
                      class="uk-form-icon uk-form-icon-flip icon-upload"
                    >
                    <input
                      class="uk-input"
                      placeholder="Unggah Dokumen"
                    >
                  </div>
                  <div
                    v-show="errors.has('east_land_photo')"
                    class="uk-text-small uk-text-danger"
                  >
                    {{ errors.first('east_land_photo') }}
                  </div>
                </div>
                <div v-else>
                  <div 
                    :class="{'uk-input-disabled' : isEdit}"
                    class="uk-width-1-1 uk-input uk-flex uk-flex-between"
                  >
                    <span class="truncate">
                      {{ formData.east_land_photo.name }}
                    </span>
                    <div class="uk-flex">
                      <img
                        v-lazy="isEdit ? `${images}/icon/eye-line.svg` : `${images}/icon/close-green.svg`"
                        alt="icon action"
                        class="icon-upload img-click"
                        @click="handlePreviewImage('east_land_photo')"
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="uk-child-width-expand@s uk-grid-small"
                uk-grid
              >
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                  Lahan Menghadap Utara
                </div>
                <div
                  v-if="!formData.north_land_photo"
                  class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl js-upload"
                  uk-form-custom
                >
                  <input
                    v-validate="{ 'required': formData.north_land_photo ? false : true }"
                    name="north_land_photo"
                    :class="{'uk-form-danger': errors.has('north_land_photo')}"
                    accept="image/png,image/jpg,image/jpeg"
                    type="file"
                    multiple
                    :disabled="isEdit"
                    @change="(event) => handleUploadFile(event, 'north_land_photo')"
                  >
                  <div
                    class="uk-inline"
                    tabindex="-1"
                  >
                    <img
                      v-lazy="`${images}/icon/logout.svg`"
                      alt="icon arrow"
                      class="uk-form-icon uk-form-icon-flip icon-upload"
                    >
                    <input
                      class="uk-input"
                      placeholder="Unggah Dokumen"
                    >
                  </div>
                  <div
                    v-show="errors.has('north_land_photo')"
                    class="uk-text-small uk-text-danger"
                  >
                    {{ errors.first('north_land_photo') }}
                  </div>
                </div>
                <div v-else>
                  <div 
                    :class="{'uk-input-disabled' : isEdit}"
                    class="uk-width-1-1 uk-input uk-flex uk-flex-between"
                  >
                    <span class="truncate">
                      {{ formData.north_land_photo.name }}
                    </span>
                    <div class="uk-flex">
                      <img
                        v-lazy="isEdit ? `${images}/icon/eye-line.svg` : `${images}/icon/close-green.svg`"
                        alt="icon action"
                        class="icon-upload img-click"
                        @click="handlePreviewImage('north_land_photo')"
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l uk-width-1-2@xl">
              <div
                class="uk-child-width-expand@s uk-grid-small"
                uk-grid
              >
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                  Lahan Menghadap Selatan
                </div>
                <div
                  v-if="!formData.south_land_photo"
                  class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl js-upload"
                  uk-form-custom
                >
                  <input
                    v-validate="{ 'required': formData.south_land_photo ? false : true }"
                    name="south_land_photo"
                    class="uk-input"
                    :class="{'uk-form-danger': errors.has('south_land_photo')}"
                    accept="image/png,image/jpg,image/jpeg"
                    type="file"
                    multiple
                    :disabled="isEdit"
                    @change="(event) => handleUploadFile(event, 'south_land_photo')"
                  >
                  <div
                    class="uk-inline"
                    tabindex="-1"
                  >
                    <img
                      v-lazy="`${images}/icon/logout.svg`"
                      alt="icon arrow"
                      class="uk-form-icon uk-form-icon-flip icon-upload"
                    >
                    <input
                      class="uk-input"
                      placeholder="Unggah Dokumen"
                    >
                  </div>
                  <div
                    v-show="errors.has('south_land_photo')"
                    class="uk-text-small uk-text-danger"
                  >
                    {{ errors.first('south_land_photo') }}
                  </div>
                </div>
                <div v-else>
                  <div
                    
                    :class="{'uk-input-disabled' : isEdit}"
                    class="uk-width-1-1 uk-input uk-flex uk-flex-between"
                  >
                    <span class="truncate">
                      {{ formData.south_land_photo.name }}
                    </span>
                    <div class="uk-flex">
                      <img
                        v-lazy="isEdit ? `${images}/icon/eye-line.svg` : `${images}/icon/close-green.svg`"
                        alt="icon action"
                        class="icon-upload img-click"
                        @click="handlePreviewImage('south_land_photo')"
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="uk-child-width-expand@s uk-grid-small"
                uk-grid
              >
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                  Tanggal Mulai Survei
                </div>
                <div
                  class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl"
                >
                  <div class="uk-inline">
                    <datepicker
                      ref="survey_start_date"
                      v-model="formData.survey_start_date"
                      v-validate="{ required: true }"
                      name="survey_start_date"
                      input-class="uk-input"
                      placeholder="dd/mm/yyyy"
                      :disabled-dates="startDisabled"
                      :disabled="isEdit || isVerified"
                    />
                    <span>
                      <img
                        v-lazy="`${images}/icon/date.svg`"
                        alt="icon arrow"
                        class="uk-form-icon uk-form-icon-flip icon-upload"
                      >
                    </span>
                  </div>
                  <div
                    v-show="errors.has('survey_start_date')"
                    class="uk-text-small uk-text-danger"
                  >
                    {{ errors.first('survey_start_date') }}
                  </div>
                </div>
              </div>
              <div
                class="uk-child-width-expand@s uk-grid-small uk-margin-top"
                uk-grid
              >
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                  Tanggal Akhir Survei
                </div>
                <div
                  class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl"
                >
                  <div class="uk-inline">
                    <datepicker
                      ref="survey_end_date"
                      v-model="formData.survey_end_date"
                      v-validate="{ required: true }"
                      name="survey_end_date"
                      input-class="uk-input"
                      placeholder="dd/mm/yyyy"
                      :disabled-dates="endDisabled"
                      :disabled="isEdit || !formData.survey_start_date || isVerified"
                    />
                    <span>
                      <img
                        v-lazy="`${images}/icon/date.svg`"
                        alt="icon arrow"
                        class="uk-form-icon uk-form-icon-flip icon-upload"
                      >
                    </span>
                  </div>
                  <div
                    v-show="errors.has('survey_end_date')"
                    class="uk-text-small uk-text-danger"
                  >
                    {{ errors.first('survey_end_date') }}
                  </div>
                </div>
              </div>
              <div
                class="uk-child-width-expand@s uk-grid-small uk-margin-bottom"
                uk-grid
              >
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl uk-margin-auto-left">
                  <div class="upload-card">
                    <div class="uk-width-1-1 uk-text-center uk-margin-small-bottom text-medium">
                      Tanda Tangan Digital
                    </div>
                    <div
                      class="uk-width-1-1 js-upload"
                      uk-form-custom
                    >
                      <input
                        ref="field_supervisor_sign"
                        v-validate="{ required: formData.field_supervisor_sign ? false : true }"
                        class="uk-input"
                        type="file"
                        name="field_supervisor_sign"
                        accept="image/png,image/jpg,image/jpeg"
                        :disabled="!isSav"
                        @change="handleUploadSign"
                      >
                      <div
                        class="uk-card uk-card-body uk-width-1-1 uk-flex uk-flex-center uk-flex-middle preview-image uk-padding-small card-upload"
                        tabindex="-1"
                      >
                        <template
                          v-if="!isSav"
                        >
                          <img
                            :src="previewImagePpl.includes('base64') ? previewImagePpl : `${image_url}${previewImagePpl}`"
                            class="preview-image"
                            alt="sign ppl"
                            uk-img
                          >
                        </template>
                        <template
                          v-else
                        >
                          <img
                            v-if="previewImagePpl.includes('base64')"
                            v-lazy="previewImagePpl.includes('base64') ? previewImagePpl : `${image_url}${previewImagePpl}`"
                            class="preview-image"
                            alt="sign ppl"
                            uk-img
                          >
                          <template v-else>
                            <img
                              v-lazy="`${images}/icon/logout.svg`"
                              alt="icon upload"
                              class="uk-margin-small-right icon-upload"
                            >
                            <div class="label-upload">
                              Unggah TTD Anda Disini
                            </div>
                          </template>
                        </template> 
                      </div>
                      <div>
                        <div
                          class="uk-width-1-1 uk-text-center uk-margin-small-bottom text-medium uk-margin-top"
                        >
                          <span
                            class="uk-text-center"
                          >
                            {{ !isSav ? formData.surveyor_signature_name : whoSign.name || '-' }} ( {{ !isSav ? formData.surveyor_signature_role : whoSign.role || '-' }} )
                          </span>
                        </div>
                      </div>
                      <div
                        v-show="errors.has('field_supervisor_sign')"
                        class="uk-text-small uk-text-danger uk-position-absolute"
                      >
                        {{ errors.first('field_supervisor_sign') }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            v-show="!(dataDetail.status_acquisition == encodeStatusMitra(3))"
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div
              v-if="!isSav && standBy && dataDetail.status_acquisition == encodeStatusMitra(2)"
              class="uk-width-1-1 uk-text-right"
            >
              <button
                class="uk-button uk-button-primary uk-margin-top uk-margin-remove-top-med uk-margin-remove-left@s uk-margin-left-med uk-width-expand uk-width-auto@s"
                type="button"
                :disabled="disabledEdit"
                @click="handleActionEdit"
              >
                <img
                  v-lazy="`${images}/icon/edit.svg`"
                  alt=""
                  class="uk-margin-small-right"
                >
                Ubah Data Survei
              </button>
              <button
                v-if="(dataDetail.status_acquisition==encodeStatusMitra(2))"
                class="uk-button uk-button-primary uk-margin-top uk-margin-remove-top-med uk-margin-remove-left@s uk-margin-left-med uk-width-expand uk-width-auto@s"
                type="button"
                @click="handleActionVerify"
              >
                Verifikasi Mitra
              </button>
            </div>
            <div
              v-show="isSav || !standBy"
              class="uk-width-1-1 uk-text-right"
            >
              <button
                class="uk-button uk-button-danger uk-width-expand uk-width-auto@s"
                type="button"
                @click="handleClose"
              >
                <img
                  v-lazy="`${images}/icon/close.svg`"
                  alt=""
                  class="uk-margin-small-right"
                >
                Batal
              </button>
              <button
                class="uk-button uk-button-primary uk-margin-top uk-margin-remove-top-med uk-margin-remove-left@s uk-margin-left-med uk-width-expand uk-width-auto@s"
                type="button"
                @click="handleActionSurvey"
              >
                <img
                  v-lazy="`${images}/icon/save.svg`"
                  alt=""
                  class="uk-margin-small-right"
                >
                Simpan
              </button>
            </div>
          </div>
        </div>  
      </div>
    </div>
    <!-- Response for Pop-up survei -->
    <modal-survei 
      :payload="formData"
      :data-detail="dataDetail"
      :eligible="eligible"
    />
    <!-- Response for Pop-up Document Preview -->
    <modal-preview
      :target-preview="blob"
      :target-field="'modal-preview-mitra'"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { PREFIX_IMAGE, CAGE_DIRECTION, SUPPORTING_FACILITIES, LAND_STATUS, ACCEPTED_IMAGE } from '@/utils/constant'
import { dateString, getBase64, compressedImage, encodeStatusMitra } from '@/utils/formater'
import ModalPreview from '@/components/globals/modal/ModalPreview'
import BreadCrumb from '@/components/globals/breadcrumb'
import ModalSurvei from './ModalSurvei'
import Datepicker from 'vuejs-datepicker'
import { notificationDanger } from '@/utils/notification'
import { getUserProfile } from '@/utils/auth'


export default {
  components: {
    BreadCrumb,
    ModalSurvei,
    Datepicker,
    ModalPreview
  },
  data() {
    return {
      standBy:true,
      ukuranLahanTooltip: 
      'title:<div class="uk-margin-small-top"><ul><li>(Untuk PARANJE 5000: Minimal 50m x 18m, dengan luasan kandang 30m x 6m, dan minimal 200m dari rumah terdekat)</li>'+
      '<li>(Untuk PARANJE 500: Minimal 9m x 5m, dengan luasan kandang 5m x 2m, dan minimal 10m dari rumah terdekat.)</li></ul></div>',
      isSav: this.$route.fullPath.includes('mitra-potensial'),
      images: PREFIX_IMAGE,
      image_url: process.env.VUE_APP_IMAGE_URL,
      pathName: this.$route.fullPath,
      isVerified: false,
      isEdit: false,
      notWorthy: false,
      list: [],
      formData: {},
      blob: '',
      listLandStatus: LAND_STATUS,
      listSupportingFacilities: SUPPORTING_FACILITIES,
      listCageDirection: CAGE_DIRECTION,
      listWaterResources: [],
      listAccessCage: [],
      hasOtherFacilities: false,
      hasElectrical: false,
      errorFrequentPowerOutages: false,
      errorRutr: false,
      errorElectricityAvailable: false,
      propsForm: {},
      previewImagePpl: '',
      disableDate: {},
      validateImageExtension:ACCEPTED_IMAGE,
      dataHeader: {
        title: 'Mitra Potensial',
        isBreadcrumb: true,
        breadcrumb: [
          {
            link: '/admin/akuisisi',
            title: 'Akuisisi'
          },
          {
            link: null,
            title: 'Detail Mitra'
          },
          {
            link: null,
            title: 'Mitra Potensial'
          }
        ]
      },
      errorGeotagMessage:'',
      whoSign:'',
      startDisabled: {},
      endDisabled: {},
      eligible:false,
      disabledEdit: false
    }
  },
  computed : {
    ...mapGetters({
      getDisabledEdit: 'acquisition/disabledEdit',
      dataDetail: 'acquisition/dataDetail',
      dataListWaterResources: 'acquisition/listWaterResources',
      dataListAccessCage: 'acquisition/listCageAccess',
      dataPotentialSurveyUpdate: 'acquisition/dataPotentialSurveyUpdate',
      dataPotentialSurvey: 'acquisition/dataPotentialSurvey'
    })
  },
  watch: {
    dataPotentialSurvey(){
      const isModalOpen = window.UIkit.modal('#modal-action').isToggled()
      if(isModalOpen){
        window.UIkit.modal('#modal-action').hide()
        setTimeout(() => {
          window.location.href = `/admin/akuisisi/detail/${this.$route.params.id}`
        }, 2000)
      }
    },
    dataPotentialSurveyUpdate(){
      this.standBy = true
      const isModalOpen = window.UIkit.modal('#modal-action').isToggled()
      if(isModalOpen){
        window.UIkit.modal('#modal-action').hide()
      }
    },
    dataDetail() {
      this.disabledEdit = this.getDisabledEdit
      this.handleDataPartnerPotential()
      if(this.dataDetail.status_acquisition != encodeStatusMitra(2)){
        this.isVerified = true
      }
      this.startDisabled= {
        to: new Date(new Date(this.dataDetail.updated_at_prospect).setHours(0, 0, 0, 0))
      }
    },
    'formData.coordinate_name'(){
      const parts = this.formData.coordinate_name.split(",")
      if (parts.length !== 2) {
        return this.errorGeotagMessage = "Sesuaikan Format [Latitude, Longitude]"
      }
      const latitude = parseFloat(parts[0])
      const longitude = parseFloat(parts[1])
      if (latitude < -90 || latitude > 90) {
        return this.errorGeotagMessage = "Latitude Range -90 to 90"
      }
      if (longitude < -180 || longitude > 180) {
        return this.errorGeotagMessage = "Longitude Range -180 to 180"
      }
      return this.errorGeotagMessage = ''
    },
    'formData.frequent_power_outages'(){
      if(this.formData.frequent_power_outages) return this.errorFrequentPowerOutages = false
    },
    'formData.rutr_rdtr_cage'(){
      if(this.formData.rutr_rdtr_cage) return this.errorRutr = false
    },
    'formData.electricity_available'() {
      if (this.formData.electricity_available){
        this.errorElectricityAvailable = false
      }
      if (this.formData.electricity_available == 2) {
        this.formData.electrical_power = ''
        this.formData.location_power_stability_start = ''
        this.formData.location_power_stability_end = ''
        this.formData.frequent_power_outages = 0

        this.hasElectrical  = true
      } else {
        return this.hasElectrical  = false
      }
    },
    'formData.survey_start_date'() {
      if(new Date(new Date(this.formData.survey_start_date).getTime()) > new Date(new Date(this.formData.survey_end_date).getTime())){
        this.formData.survey_end_date = ''
      }
      this.formData.survey_start_date = this.dateFormatFilter(this.formData.survey_start_date)
      this.endDisabled = {
        to: new Date(this.formData.survey_start_date)
      }
    },
    'formData.survey_end_date'() {
      this.formData.survey_end_date = this.dateFormatFilter(this.formData.survey_end_date)
    }
  },
  async beforeMount() {
    await this.getDataDetail(`${this.$route.params.id}`)
    if(this.isSav){
      if (this.dataDetail.status_acquisition != encodeStatusMitra(2) || this.dataDetail.acquisition_info.acquisition_id) {
        this.$router.push(`/admin/akuisisi/detail/${this.$route.params.id}`)
        setTimeout(() => {
          notificationDanger({
            message:this.dataDetail.status_acquisition == encodeStatusMitra(2) ? 
              'Status Survey Menunggu Verifikasi!' : `Status Akuisisi Di Tahap ${this.dataDetail.status_acquisition}`})
        }, 1000)
      }
    }
  },
  async mounted(){
    this.whoSign = getUserProfile()
    await this.getListForSurveyForm()
    this.listWaterResources = this.dataListWaterResources ? this.dataListWaterResources : []
    this.listAccessCage = this.dataListAccessCage ? this.dataListAccessCage : []
  },
  methods: {
    ...mapMutations({
      setStatusSurvey: 'acquisition/SET_STATUS_SURVEY'
    }),
    backToPreviousModalForSurvey(){
      this.setStatusSurvey(false)
    },
    encodeStatusMitra(s){
      return encodeStatusMitra(s)
    },
    ...mapActions({
      getDataDetail: 'acquisition/getDataDetail',
      getListForSurveyForm: 'acquisition/getListForSurveyForm'
    }),
    dateFormatFilter(date) {
      return dateString(date)
    },
    handleDataPartnerPotential() {
      if (this.dataDetail) {
        // this section for get data detail status and pass to form data
        if (!this.pathName.includes('mitra-potensial')) {
          this.isEdit = true
          const detailFile = this.dataDetail.acquisition_location_photo
          const detailInfo = this.dataDetail.acquisition_info
          this.formData = {
            ...detailInfo,
            surveyor_signature_name: detailInfo.surveyor_signature_name,
            surveyor_signature_role: detailInfo.surveyor_signature_role, 
            land_status: this.listLandStatus.filter(item => item.name == detailInfo.land_status)[0],
            supporting_facilities: this.listSupportingFacilities.filter(item => item.name == detailInfo.supporting_facilities)[0],
            cage_direction: this.listCageDirection.filter(item => item.name == detailInfo.cage_direction)[0],
            access_to_location_cage: detailInfo.access_to_location_cage,
            water_sources: detailInfo.water_sources,
            field_supervisor_sign: detailFile.field_supervisor_sign,
            east_land_photo: {
              name: detailFile.east_land_photo ? detailFile.east_land_photo.split('/')[3] : '',
              data: detailFile.east_land_photo
            },
            north_land_photo: {
              name: detailFile.north_land_photo ? detailFile.north_land_photo.split('/')[3] : '',
              data: detailFile.north_land_photo
            },
            south_land_photo: {
              name: detailFile.south_land_photo ? detailFile.south_land_photo.split('/')[3] : '',
              data: detailFile.south_land_photo
            },
            west_land_photo: {
              name: detailFile.west_land_photo ? detailFile.west_land_photo.split('/')[3] : '',
              data: detailFile.west_land_photo
            },
            survey_end_date: detailFile.survey_end_date,
            survey_start_date: detailFile.survey_start_date
          }
          this.previewImagePpl = detailFile.field_supervisor_sign
        } else {
          this.formData = {
            ...this.formData,
            id: this.dataDetail.id,
            applicant_name: this.dataDetail.name,
            phone_number: this.dataDetail.phone_number,
            applicant_address: this.dataDetail.address,
            cage_address: this.dataDetail.land_address,
            location_power_stability_start: this.dataDetail.acquisition_info.location_power_stability_start,
            location_power_stability_end: this.dataDetail.acquisition_info.location_power_stability_end,
            location_temperature_start: this.dataDetail.acquisition_info.location_temperature_start,
            location_temperature_end: this.dataDetail.acquisition_info.location_temperature_end,
            location_humidity_start: this.dataDetail.acquisition_info.location_humidity_start,
            location_humidity_end: this.dataDetail.acquisition_info.location_humidity_end
          }
        }
      }
    },
    async handleUploadFile(event, target) {
      const file = event.target.files[0]
      const fileExtension = file.name.split('.').pop()
      if (this.validateImageExtension.includes(fileExtension)) {
        const compressedFile = await compressedImage(file, 1)
        this.formData = {
          ...this.formData,
          [target]: {
            data: compressedFile,
            name: compressedFile.name
          }
        }
      } else {
        notificationDanger({message:'Invalid image extension!'})
      }
    },
    handleActionEdit() {
      this.isEdit = !this.isEdit
      this.standBy = false
      this.errors.items.splice(0, this.errors.items.length)
    },
    handleClose() {
      if (this.pathName.includes('mitra-potensial')) {
        this.$router.push(`/admin/akuisisi/detail/${this.$route.params.id}`)
      } else {
        this.isEdit = !this.isEdit
        this.standBy = true
        this.handleDataPartnerPotential()
      }
    },
    handleActionSurvey() {
      this.$validator.validateAll().then(async (success) => {
        if (success) {
          this.eligible=false
          if (this.$validator.errors.any()) return
          window.UIkit.modal('#modal-action').show()
        }
      })
      if(!this.formData.frequent_power_outages ){
        this.errorFrequentPowerOutages = 'Choose One'
      }
      if(!this.formData.rutr_rdtr_cage){
        this.errorRutr = 'Choose One'
      }
      if(!this.formData.electricity_available){
        this.errorElectricityAvailable = 'Choose One'
      }
    },
    handleActionVerify(){
      this.eligible=true
      this.backToPreviousModalForSurvey()
      window.UIkit.modal('#modal-action').show()
    },
    async handleUploadSign() {
      let imgPpl = this.$refs.field_supervisor_sign.files[0]
      const fileExtension = imgPpl.name.split('.').pop()
      if (this.validateImageExtension.includes(fileExtension)) {
        const compressedFile = await compressedImage(imgPpl, 1)
        this.formData = {
          ...this.formData,
          field_supervisor_sign: compressedFile
        }
        return getBase64(imgPpl, file => this.previewImagePpl = file )
      } else {
        notificationDanger({message:'Invalid image extension!'})
      }
    },
    handlePreviewImage(target) {
      if (this.isEdit) {
        const dataPreview = this.formData[target].data
        this.blob = dataPreview.includes('base64') ? dataPreview : this.image_url + dataPreview
        window.UIkit.modal('#modal-preview-mitra').show()
      } else {
        this.formData[target] = ''
      }
    }
  }
}
</script>

<style scoped>
  .card-failed {
    background: #F8E7E7;
    border-radius: 16px;
    padding: 12px 24px;
  }

  .attention-failed {
    color: #F92626;
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    margin-bottom: 8px;
  }

  .preview-image {
    width: auto;
    height: 150px;
    object-fit: cover;
    padding: 10px;
  }

  .icon-upload {
    padding: 8px;
  }
</style>
