<template>
  <div
    class="uk-child-width-expand@s uk-grid-small"
    uk-grid
  >
    <div class="uk-width-1-1 uk-text-left">
      <strong>
        Mitra Tidak Layak
      </strong>
    </div>
    <div class="uk-form-horizontal">
      <div
        class="uk-child-width-expand@s uk-grid-small"
        uk-grid
      >
        <div class="uk-width-1-1">
          <div 
            class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-margin-remove"
            uk-form-custom
          >
            <label
              class="uk-form-label uk-text-left label-green"
              for="log_activity"
            >
              Pilih Alasan
            </label>
            <div class="uk-form-controls">
              <multiselect 
                id="reason_not_interested" 
                v-model="formPartnerPotential.reason"
                v-validate="'required'"
                name="reason"
                placeholder="Pilih Alasan" 
                open-direction="bottom" 
                :options="listReason" 
                :searchable="true" 
                :close-on-select="true" 
                :max-height="500" 
                :show-no-results="true"
              >
                <span slot="noResult">Oops! Data tidak ditemukan.</span>
              </multiselect>
              <div
                v-show="errors.has('reason')"
                class="uk-text-small uk-text-danger"
              >
                {{ errors.first('reason') }}
              </div>
            </div>
            <div
              v-if="formPartnerPotential.reason == 'Lainnya'"
              class="uk-form-controls uk-margin-top"
            >
              <input
                id="other_reason"
                v-model="formPartnerPotential.other_reason"
                v-validate="{ required: true }"
                class="uk-input"
                name="other_reason"
                type="text"
                placeholder="Masukan Alasan Lainnya"
                :class="{'uk-form-danger': errors.has('other_reason'), 'uk-input': true}"
              >
              <div
                v-show="errors.has('other_reason')"
                class="uk-text-small uk-text-danger"
              >
                {{ errors.first('other_reason') }}
              </div>
            </div>
          </div>
        </div>
        <div class="uk-width-1-1">
          <label
            class="uk-form-label uk-text-left label-green"
            for="log_activity"
          >
            Tanggal Survei
          </label>
          <div
            class="uk-form-controls uk-flex uk-flex-middle uk-padding-left uk-grid-small uk-child-width-expand@s"
            uk-grid
          >
            <div class="uk-width-expand uk-padding-remove">
              <datepicker
                ref="survey_start_date"
                v-model="formPartnerPotential.survey_start_date"
                v-validate="{ required: true }"
                name="survey_start_date"
                input-class="uk-input"
                placeholder="dd/mm/yyyy"
                :disabled-dates="startDisabled"
                :class="{'uk-form-danger': errors.has('survey_start_date')}"
              />
              <div
                v-show="errors.has('survey_start_date')"
                class="uk-text-small uk-text-danger"
              >
                {{ errors.first('survey_start_date') }}
              </div>
            </div>
            <div class="uk-width-1-5 uk-padding-remove">
              to
            </div>
            <div class="uk-width-expand uk-padding-remove">
              <datepicker
                ref="survey_end_date"
                v-model="formPartnerPotential.survey_end_date"
                v-validate="{ required: true }"
                name="survey_end_date"
                input-class="uk-input"
                placeholder="dd/mm/yyyy"
                :disabled="!formPartnerPotential.survey_start_date"
                :disabled-dates="endDisabled"
                :class="{'uk-form-danger': errors.has('survey_end_date')}"
              /> 
              <div
                v-show="errors.has('survey_end_date')"
                class="uk-text-small uk-text-danger"
              >
                {{ errors.first('survey_end_date') }}
              </div> 
            </div>
          </div>
        </div>
      </div>
      <div class="uk-width-1-1 uk-margin-top">
        <div 
          class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-margin-remove"
          uk-form-custom
        >
          <label
            class="uk-form-label uk-text-left label-green"
          >
            Foto Lahan Timur<span class="uk-text-danger">*</span>
          </label>
          <div class="uk-form-controls">
            <input
              v-validate="{ 'required':true }"
              name="east_land_photo"
              class="{'uk-form-danger': errors.has('east_land_photo')}"
              accept="image/png,image/jpg,image/jpeg"
              type="file"
              multiple
              @change="(event) => handleUploadFile(event, 'east_land_photo')"
            >
            <div
              class="uk-inline"
              tabindex="-1"
            >
              <!-- <img
                v-lazy="formPartnerPotential.east_land_photo && encodeStatusMitra(3) ? `${images}/icon/eye.svg` : `${images}/icon/logout.svg`"
                alt="icon upload"
                class="uk-form-icon uk-form-icon-flip icon-upload"
              > -->
              <div class="uk-input">
                <span class="uk-panel uk-text-truncate">
                  {{ formPartnerPotential.east_land_photo ? formPartnerPotential.east_land_photo.name : 'Unggah Foto Lahan Timur' }}
                </span>
              </div>
            </div>
            <div
              v-show="errors.has('east_land_photo')"
              class="uk-text-small uk-text-danger"
            >
              {{ errors.first('east_land_photo') }}
            </div>
          </div>
        </div>
      </div>
      <div class="uk-width-1-1 uk-margin-top">
        <div 
          class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-margin-remove"
          uk-form-custom
        >
          <label
            class="uk-form-label uk-text-left label-green"
          >
            Foto Lahan Barat<span class="uk-text-danger">*</span>
          </label>
          <div class="uk-form-controls">
            <input
              v-validate="{ 'required':true }"
              name="west_land_photo"
              class="{'uk-form-danger': errors.has('west_land_photo')}"
              accept="image/png,image/jpg,image/jpeg"
              type="file"
              multiple
              @change="(event) => handleUploadFile(event, 'west_land_photo')"
            >
            <div
              class="uk-inline"
              tabindex="-1"
            >
              <!-- <img
                v-lazy="formPartnerPotential.west_land_photo && encodeStatusMitra(3) ? `${images}/icon/eye.svg` : `${images}/icon/logout.svg`"
                alt="icon upload"
                class="uk-form-icon uk-form-icon-flip icon-upload"
              > -->
              <div class="uk-input">
                <span class="uk-panel uk-text-truncate">
                  {{ formPartnerPotential.west_land_photo ? formPartnerPotential.west_land_photo.name : 'Unggah Foto Lahan Barat' }}
                </span>
              </div>
            </div>
            <div
              v-show="errors.has('west_land_photo')"
              class="uk-text-small uk-text-danger"
            >
              {{ errors.first('west_land_photo') }}
            </div>
          </div>
        </div>
      </div>
      <div class="uk-width-1-1 uk-margin-top">
        <div 
          class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-margin-remove"
          uk-form-custom
        >
          <label
            class="uk-form-label uk-text-left label-green"
          >
            Foto Lahan Selatan<span class="uk-text-danger">*</span>
          </label>
          <div class="uk-form-controls">
            <input
              v-validate="{ 'required':true }"
              name="south_land_photo"
              class="{'uk-form-danger': errors.has('south_land_photo')}"
              accept="image/png,image/jpg,image/jpeg"
              type="file"
              multiple
              @change="(event) => handleUploadFile(event, 'south_land_photo')"
            >
            <div
              class="uk-inline"
              tabindex="-1"
            >
              <!-- <img
                v-lazy="formPartnerPotential.south_land_photo && encodeStatusMitra(3) ? `${images}/icon/eye.svg` : `${images}/icon/logout.svg`"
                alt="icon upload"
                class="uk-form-icon uk-form-icon-flip icon-upload"
              > -->
              <div class="uk-input">
                <span class="uk-panel uk-text-truncate">
                  {{ formPartnerPotential.south_land_photo ? formPartnerPotential.south_land_photo.name : 'Unggah Foto Lahan Selatan' }}
                </span>
              </div>
            </div>
            <div
              v-show="errors.has('south_land_photo')"
              class="uk-text-small uk-text-danger"
            >
              {{ errors.first('south_land_photo') }}
            </div>
          </div>
        </div>
      </div>
      <div class="uk-width-1-1 uk-margin-top">
        <div 
          class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-margin-remove"
          uk-form-custom
        >
          <label
            class="uk-form-label uk-text-left label-green"
          >
            Foto Lahan Utara<span class="uk-text-danger">*</span>
          </label>
          <div class="uk-form-controls">
            <input
              v-validate="{ 'required':true }"
              name="north_land_photo"
              class="{'uk-form-danger': errors.has('north_land_photo')}"
              accept="image/png,image/jpg,image/jpeg"
              type="file"
              multiple
              @change="(event) => handleUploadFile(event, 'north_land_photo')"
            >
            <div
              class="uk-inline"
              tabindex="-1"
            >
              <!-- <img
                v-lazy="formPartnerPotential.north_land_photo && encodeStatusMitra(3) ? `${images}/icon/eye.svg` : `${images}/icon/logout.svg`"
                alt="icon upload"
                class="uk-form-icon uk-form-icon-flip icon-upload"
              > -->
              <div class="uk-input">
                <span class="uk-panel uk-text-truncate">
                  {{ formPartnerPotential.north_land_photo ? formPartnerPotential.north_land_photo.name : 'Unggah Foto Lahan Utara' }}
                </span>
              </div>
            </div>
            <div
              v-show="errors.has('north_land_photo')"
              class="uk-text-small uk-text-danger"
            >
              {{ errors.first('north_land_photo') }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="uk-width-1-1 uk-text-right uk-margin-top">
      <button
        class="uk-button uk-button-primary uk-margin-top uk-margin-remove-top-med uk-margin-remove-left@s uk-margin-left-med uk-width-expand uk-width-auto@s"
        type="button"
        :disabled="isLoading"
        @click="() => handleSubmit()"
      >
        <span
          v-if="isLoading"
          uk-spinner
          class="button-spinner"
        />
        Simpan
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { dateString } from '@/utils/formater'
import { notificationDanger } from '@/utils/notification'
import Datepicker from 'vuejs-datepicker'
import { PREFIX_IMAGE, ACCEPTED_IMAGE } from '@/utils/constant'
import { encodeStatusMitra, compressedImage } from '@/utils/formater'

export default {
  components: {
    Datepicker
  },
  data() {
    return {
      allowedExtensions:['png', 'jpg', 'jpeg'],
      startDisabled: {},
      endDisabled: {},
      images: PREFIX_IMAGE,
      formPartnerPotential: {
        status_acquisition: encodeStatusMitra(3)
      },
      listReason: [],
      isLoading: false,
      validateImageExtension:ACCEPTED_IMAGE
    }
  },
  computed : {
    ...mapGetters({
      dataDetail: 'acquisition/dataDetail',
      dataPotential: 'acquisition/dataPotential',
      dataListsNotWorthy: 'acquisition/listsNotWorthy'
    })
  },
  watch: {
    dataDetail() {
      if(this.dataDetail){
        this.startDisabled= {
          to: new Date(new Date(this.dataDetail.updated_at_prospect).setHours(0, 0, 0, 0))
        }
      }
      if(this.dataDetail.acquisition_location_photo.acquisition_id){
        this.assignData()
      }
    },
    'formPartnerPotential.survey_start_date'() {
      if(new Date(new Date(this.formPartnerPotential.survey_start_date).getTime()) > new Date(new Date(this.formPartnerPotential.survey_end_date).getTime())){
        this.formPartnerPotential.survey_end_date = ''
      }
      this.formPartnerPotential.survey_start_date = this.dateFormatFilter(this.formPartnerPotential.survey_start_date)
      this.endDisabled = {
        to: new Date(this.formPartnerPotential.survey_start_date)
      }
    },
    'formPartnerPotential.survey_end_date'() {
      this.formPartnerPotential.survey_end_date = this.dateFormatFilter(this.formPartnerPotential.survey_end_date)
    }
  },
  async mounted(){
    await this.getListNotWorthy()
    if(this.dataListsNotWorthy){
      this.listReason= this.dataListsNotWorthy
    }
  },
  methods: {
    encodeStatusMitra(num){
      return encodeStatusMitra(num)
    },
    async assignData(){
      const detailFile = this.dataDetail.acquisition_location_photo
      this.formPartnerPotential = {
        survey_start_date: detailFile.survey_start_date,
        survey_end_date: detailFile.survey_end_date
      }
    //   east_land_photo: {
    //     name: detailFile.east_land_photo ? detailFile.east_land_photo.split('/')[3] : '',
    //     data: await this.takeImage(detailFile.east_land_photo)
    //   },
    //   west_land_photo: {
    //     name: detailFile.west_land_photo ? detailFile.west_land_photo.split('/')[3] : '',
    //     data: await this.takeImage(detailFile.west_land_photo)
    //   },
    //   south_land_photo: {
    //     name: detailFile.south_land_photo ? detailFile.south_land_photo.split('/')[3] : '',
    //     data: await this.takeImage(detailFile.south_land_photo)
    //   },
    //   north_land_photo: {
    //     name: detailFile.north_land_photo ? detailFile.north_land_photo.split('/')[3] : '',
    //     data: await this.takeImage(detailFile.north_land_photo)
    //   }
    // }
    // this.handleUploadFile(await this.takeImage(detailFile.east_land_photo),'east_land_photo')
    // this.handleUploadFile(await this.takeImage(detailFile.west_land_photo),'west_land_photo')
    // this.handleUploadFile(await this.takeImage(detailFile.south_land_photo),'south_land_photo')
    // this.handleUploadFile(await this.takeImage(detailFile.north_land_photo),'north_land_photo')
    // console.log(this.formPartnerPotential)
    },
    async takeImage(url) {
      const response = await fetch(url)
      const blob = await response.blob()
      const filename = url.substring(url.lastIndexOf('/') + 1)
      const file = new File([blob], filename, { type: blob.type })
      return file
    },
    ...mapMutations({
      setStatusSurvey: 'acquisition/SET_STATUS_SURVEY'
    }),
    ...mapActions({
      getListNotWorthy: 'acquisition/getListsNotWorthy',
      getPartnerPotential: 'acquisition/getPartnerPotential',
      getDataDetail: 'acquisition/getDataDetail'
    }),
    fileExtensionChecker(newFile){
      return newFile.name.split('.').pop().toLowerCase()
    },
    dateFormatFilter(date) {
      return dateString(date)
    },
    async handleUploadFile(event, target) {
      const file = event.target.files[0]
      const fileExtension = file.name.split('.').pop()
      if (this.validateImageExtension.includes(fileExtension)) {
        const compressedFile = await compressedImage(file, 1)
        this.formPartnerPotential = {
          ...this.formPartnerPotential,
          [target]: {
            data: compressedFile,
            name: compressedFile.name
          }
        }
        const input = event.target
        input.dispatchEvent(new Event('change', { target: input }))
        this.$validator.clear()
      } else {
        notificationDanger({message:'Invalid image extension!'})
      }
    },
    handleSubmit() {
      this.$validator.validateAll().then(async (success) => {
        const formData = new FormData()
        if (success) {
          this.isLoading = true
          if (this.$validator.errors.any()) return
          this.formPartnerPotential.status_acquisition=encodeStatusMitra(3)
          formData.append('west_land_photo', this.formPartnerPotential.west_land_photo ? this.formPartnerPotential.west_land_photo.data : '')
          formData.append('east_land_photo', this.formPartnerPotential.east_land_photo ? this.formPartnerPotential.east_land_photo.data : '')
          formData.append('north_land_photo', this.formPartnerPotential.north_land_photo ? this.formPartnerPotential.north_land_photo.data : '')
          formData.append('south_land_photo', this.formPartnerPotential.south_land_photo ? this.formPartnerPotential.south_land_photo.data : '')
          formData.append('survey_start_date', this.formPartnerPotential.survey_start_date || '')
          formData.append('survey_end_date', this.formPartnerPotential.survey_end_date || '')
          formData.append('status_acquisition', this.formPartnerPotential.status_acquisition)
          formData.append('reason', this.formPartnerPotential.reason)
          formData.append('other_reason', this.formPartnerPotential.other_reason)
          await this.getPartnerPotential({ id: this.dataDetail.id , payload: formData })
          if (this.dataPotential == 'OK') {
            location.reload()
          }
        }
      })
    }
  }
}
</script>

<style scoped>
  .icon-upload {
    padding: 8px;
    cursor: pointer;
  }
</style>
