<template>
  <div>
    <div
      id="modal-action"
      uk-modal
      esc-close="false"
      bg-close="true"
    >
      <div class="uk-modal-dialog uk-modal-body">
        <div v-if="statusSurvey==true">
          <partner-potential :data-survey="dataSurvey" />
        </div>
        <div v-else>
          <div v-if="!eligible">
            <div class="uk-width-1-1 uk-text-center">
              <strong>
                Data Survei
              </strong>
              <p>
                Apakah data survei sudah benar?
              </p>
            </div>
            <div
              class="uk-child-width-expand@s uk-grid-small"
              uk-grid
            >
              <div class="uk-width-1-1 uk-text-center">
                <button
                  class="uk-button uk-button-default uk-width-expand uk-width-auto@s"
                  type="button"
                  @click="handleClose"
                >
                  Batal
                </button>
                <button
                  class="uk-button uk-button-primary uk-margin-top uk-margin-remove-top-med uk-margin-remove-left@s uk-margin-left-med uk-width-expand uk-width-auto@s"
                  type="button"
                  :disabled="isLoading"
                  @click="handleActionSurvey"
                >
                  <span
                    v-if="isLoading"
                    uk-spinner
                    class="button-spinner uk-text-muted"
                  />
                  Ya, Lanjutkan
                </button>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="uk-width-1-1 uk-text-center">
              <strong>
                Konfirmasi Kelayakan 
              </strong>
              <p>
                Apakah data mitra sudah layak?
              </p>
            </div>
            <div
              class="uk-child-width-expand@s uk-grid-small"
              uk-grid
            >
              <div class="uk-width-1-1 uk-text-center">
                <button
                  class="uk-button uk-button-default uk-width-expand uk-width-auto@s"
                  type="button"
                  @click="() =>handleVerify('not_worthy')"
                >
                  Tidak Layak
                </button>
                <button
                  class="uk-button uk-button-primary uk-margin-top uk-margin-remove-top-med uk-margin-remove-left@s uk-margin-left-med uk-width-expand uk-width-auto@s"
                  type="button"
                  :disabled="isLoading"
                  @click="() =>handleVerify('worthy')"
                >
                  <span
                    v-if="isLoading"
                    uk-spinner
                    class="button-spinner uk-text-muted"
                  />
                  Ya, Layak
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { encodeStatusMitra } from '@/utils/formater'
import { notificationSuccess } from '@/utils/notification'
import PartnerPotential from '../status-mitra/PartnerPotential.vue'

export default {
  components: {
    PartnerPotential
  },
  props: {
    payload: {
      required: true,
      type: Object
    },
    eligible:{
      required: false,
      type: Boolean
    },
    dataDetail: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      isLoading:false,
      tempPayload: {},
      pathName: this.$route.fullPath,
      statusSurvey: false,
      dataSurvey:{},
      forStatusAcquisition: ''
    }
  },
  computed : {
    ...mapGetters({
      dataPotentialSurvey: 'acquisition/dataPotentialSurvey',
      dataPotentialSurveyUpdate: 'acquisition/dataPotentialSurveyUpdate',
      dataStatusSurvey: 'acquisition/statusSurvey'
    })
  },
  watch: {
    dataStatusSurvey(){
      this.dataSurvey = this.payload
      this.statusSurvey = this.dataStatusSurvey == true ? this.dataStatusSurvey : false
    }
  },
  methods: {
    ...mapMutations({
      setStatusSurvey: 'acquisition/SET_STATUS_SURVEY'
    }),
    ...mapActions({
      getPartnerPotentialSurvey: 'acquisition/getPartnerPotentialSurvey',
      getPartnerPotentialSurveyUpdate: 'acquisition/getPartnerPotentialSurveyUpdate',
      getDataDetail: 'acquisition/getDataDetail',
      verifySurvey: 'acquisition/verifySurvey'
    }),
    handleClose() {
      if (this.pathName.includes('mitra-potensial')) {
        window.UIkit.modal('#modal-action').hide()
        this.$router.push(`/admin/akuisisi/detail/${this.$route.params.id}`)
      } else {
        window.UIkit.modal('#modal-action').hide()
        this.getDataDetail(this.$route.params.id)
      }
    },
    async handleVerify(w){
      this.isLoading = true
      if(w=='worthy'){
        await this.verifySurvey(`${this.$route.params.id}`)
        notificationSuccess('Berhasil Verifikasi Mitra!')
        this.isLoading = false
      } else {
        this.setStatusSurvey(true)
        this.isLoading = false
      }
    },
    async handleActionSurvey() {
      this.isLoading = true
      const formData = new FormData()
      this.tempPayload = {
        ...this.payload,
        status_acquisition: this.forStatusAcquisition ? this.forStatusAcquisition : encodeStatusMitra(2),
        acquisition_id: this.dataDetail.id,
        access_to_location_cage: this.payload.access_to_location_cage,
        cage_direction: this.payload.cage_direction.name,
        land_status: this.payload.land_status.name,
        supporting_facilities: this.payload.supporting_facilities.name,
        water_sources: this.payload.water_sources,
        field_supervisor_sign: typeof this.payload.field_supervisor_sign == 'string' ? '' : this.payload.field_supervisor_sign,
        east_land_photo: typeof this.payload.east_land_photo.data == 'string' ? '' : this.payload.east_land_photo.data,
        north_land_photo: typeof this.payload.north_land_photo.data == 'string' ? '' : this.payload.north_land_photo.data,
        south_land_photo: typeof this.payload.south_land_photo.data == 'string' ? '' : this.payload.south_land_photo.data,
        west_land_photo: typeof this.payload.west_land_photo.data == 'string' ? '' : this.payload.west_land_photo.data
      }

      // const nullableKeys = ['field_supervisor_sign']
      // nullableKeys.includes(key) || 

      for (var key in this.tempPayload) {
        if ((this.tempPayload[key] !== "" && this.tempPayload[key] !== "status" && this.tempPayload[key] !== null && this.tempPayload[key] !== undefined)) {
          if (key === "status_acquisition" && this.tempPayload[key] === this.dataDetail.status_acquisition ) {
            console.log("no changes in status_acquisition")
          } else {
            formData.append(key, this.tempPayload[key])
          }
        } else {
          console.log(key + " is empty or null")
        }
      }
      
      if (this.pathName.includes('mitra-potensial')) {
        await this.getPartnerPotentialSurvey(formData)
        if (this.dataPotentialSurvey) {
          this.isLoading = false
        } else {
          this.isLoading = false
        }
      } else {
        await this.getPartnerPotentialSurveyUpdate(formData)
        if (this.dataPotentialSurveyUpdate) {
          this.isLoading = false
          this.getDataDetail(this.$route.params.id)
        } else {
          this.isLoading = false
        }
      }
    }
  }
}
</script>

<style scoped>
  .card-failed {
    background: #F8E7E7;
    border-radius: 16px;
    padding: 12px 24px;
  }

  .attention-failed {
    color: #F92626;
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    margin-bottom: 8px;
  }

  .icon-upload {
    padding: 8px;
  }

</style>
